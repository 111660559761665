var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"dropdown",class:[_vm.$style.dropdown, { [_vm.$style.open]: _vm.isOpen }]},[_c('div',{class:[
      _vm.$style.toggler,
      {
        [_vm.$style.error]: _vm.error,
        [_vm.$style.success]: _vm.success,
        [_vm.$style.disabled]: _vm.isDisabled,
        [_vm.$style.new]: _vm.value === 'newParameter',
      },
    ],on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_vm._v(" "+_vm._s(_vm.selectedOptionText)+" "),_c('ArrowIcon',{class:_vm.$style.arrow}),(_vm.withTrashIcon)?_c('TrashIcon',{class:_vm.$style.trash,on:{"click":function($event){$event.stopPropagation();return _vm.$emit('resetValue', _vm.nameField)}}}):_vm._e()],1),(_vm.error || _vm.success)?_c('div',{class:[
      _vm.$style.message,
      { [_vm.$style.error]: _vm.error, [_vm.$style.success]: _vm.success },
    ]},[_vm._v(" "+_vm._s(_vm.error || _vm.success)+" ")]):_vm._e(),_c('transition',{attrs:{"enter-class":_vm.$style.enter,"leave-to-class":_vm.$style.leaveTo,"enter-active-class":_vm.$style.enterActive,"leave-active-class":_vm.$style.leaveActive}},[(_vm.isOpen && !_vm.isDisabled)?_c('div',{class:_vm.$style.menu},[_c('div',{class:_vm.$style.outerWrapper},[_c('div',{class:_vm.$style.innerWrapper},_vm._l((_vm.options),function(option,index){return _c('a',{key:index,class:[
              _vm.$style.item,
              { [_vm.$style.new]: option.value === 'newParameter' },
            ],attrs:{"data-dropdown-menu-option":index,"href":""},on:{"click":function($event){$event.preventDefault();;(_vm.isOpen = false), _vm.$emit('change', option.value)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;;(_vm.isOpen = false), _vm.$emit('change', option.value)}}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0)])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }