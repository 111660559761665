const isPointInsidePolygon = (point, polygons) => {
  let insidePolygons = false
  const [x, y] = point

  polygons.map((vs) => {
    let inside = false
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      const xi = vs[i][0]
      const yi = vs[i][1]
      const xj = vs[j][0]
      const yj = vs[j][1]

      const intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
      if (intersect) inside = !inside
    }
    if (inside) insidePolygons = true
    return inside
  })

  return insidePolygons
}

export { isPointInsidePolygon }
