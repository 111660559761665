<template>
  <div ref="dropdown" :class="[$style.dropdown, { [$style.open]: isOpen }]">
    <div
      :class="[
        $style.toggler,
        {
          [$style.error]: error,
          [$style.success]: success,
          [$style.disabled]: isDisabled,
          [$style.new]: value === 'newParameter',
        },
      ]"
      @click="isOpen = !isOpen"
    >
      {{ selectedOptionText }}
      <ArrowIcon :class="$style.arrow" />
      <TrashIcon
        v-if="withTrashIcon"
        :class="$style.trash"
        @click.stop="$emit('resetValue', nameField)"
      />
    </div>
    <div
      v-if="error || success"
      :class="[
        $style.message,
        { [$style.error]: error, [$style.success]: success },
      ]"
    >
      {{ error || success }}
    </div>
    <transition
      :enter-class="$style.enter"
      :leave-to-class="$style.leaveTo"
      :enter-active-class="$style.enterActive"
      :leave-active-class="$style.leaveActive"
    >
      <div v-if="isOpen && !isDisabled" :class="$style.menu">
        <div :class="$style.outerWrapper">
          <div :class="$style.innerWrapper">
            <a
              v-for="(option, index) in options"
              :key="index"
              :data-dropdown-menu-option="index"
              :class="[
                $style.item,
                { [$style.new]: option.value === 'newParameter' },
              ]"
              href=""
              @click.prevent=";(isOpen = false), $emit('change', option.value)"
              @keydown.enter=";(isOpen = false), $emit('change', option.value)"
            >
              {{ option.text }}
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { getNextActiveElement } from '@/helpers'
import ArrowIcon from '@/assets/icons/arrow-down.svg?inline'
import TrashIcon from '@/assets/icons/trash.svg?inline'
export default {
  components: {
    ArrowIcon,
    TrashIcon,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    error: {
      type: String,
      default: '',
    },
    success: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    nameField: {
      type: String,
      default: '',
    },
    withTrashIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    isDisabled() {
      return this.disabled || !this.options.length
    },
    selectedOptionText() {
      const option = this.options.find((option) => option.value === this.value)
      return option ? option.text : this.placeholder
    },
  },
  mounted() {
    const dropdown = this.$refs.dropdown
    if (!dropdown) {
      return
    }

    document.addEventListener('click', (event) => {
      if (this.isOpen && !dropdown.contains(event.target)) {
        this.isOpen = false
      }
    })

    document.addEventListener('keydown', (e) => {
      if (this.isOpen) {
        if (e.key === 'Esc' || e.key === 'Escape') {
          e.preventDefault()
          e.stopPropagation()
          this.isOpen = false
        } else if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
          e.preventDefault()
          e.stopPropagation()

          const options = [].concat(
            ...dropdown.querySelectorAll('[data-dropdown-menu-option]'),
          )

          if (!options.length) {
            return
          }

          getNextActiveElement(
            options,
            e.target,
            e.key === 'ArrowDown',
            !options.includes(e.target),
          ).focus()
        }
      }
    })
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/mixins';

.enter,
.leaveTo {
  opacity: 0;
}
.enterActive,
.leaveActive {
  transition: opacity 0.2s ease;
}
.dropdown {
  position: relative;
  .toggler {
    @include input;
    position: relative;
    cursor: pointer;
    color: $dark-gray;
    padding-right: 3rem;
    .arrow {
      position: absolute;
      top: 50%;
      right: 1.5rem;
      transform: translateY(-50%);
      transition: transform 0.2s ease-in-out;
      > path {
        fill: $light-gray;
      }
    }

    .trash {
      position: absolute;
      top: 40%;
      right: 4rem;
      cursor: pointer;
      > path {
        fill: $light-gray;
      }
    }
  }
  .new {
    color: $cyan;
    font-weight: 700;
  }
  .message {
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: right;
    &.error {
      color: $error;
    }
    &.success {
      color: $complite;
    }
  }
  .menu {
    @include rounded;
    @include dropdownShadow;
    z-index: $z-index-drodpown;
    position: absolute;
    top: calc(100% + 0.5rem);
    left: 0;
    width: 100%;
    padding: 0.188rem;
    background: $white;
    border: 0.063rem solid $light-gray;
    .outerWrapper {
      max-height: 12rem;
      overflow-x: hidden;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 0.5rem;
      }
      &::-webkit-scrollbar-track {
        background: $smoky;
        border-radius: 0.063rem;
      }
      &::-webkit-scrollbar-thumb {
        background: $bright-gold;
        border-radius: 0.063rem;
      }
      .innerWrapper {
        padding-right: 0.125rem;
        display: flex;
        flex-direction: column;
        .item {
          display: flex;
          color: $black-gray;
          outline: none;
          padding: 0.75rem 1.5rem;
          &.new {
            color: $cyan;
            font-weight: 700;
          }
          &:not(:last-child) {
            border-bottom: 0.063rem solid $extra-light-gray;
          }
          &:hover,
          &:focus,
          &:active {
            @include goldGradient;
          }
        }
      }
    }
  }
  &.open {
    .toggler {
      background: $white;
      border-color: $light-gray;
      .arrow {
        transform: rotate(180deg) translateY(50%);
      }
    }
  }
}
</style>
